















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from '@/api/dictionary'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { isViewLater, setViewLater } from '@/api/dictionaries'

dayjs.extend(relativeTime)

@Component({
})
export default class DictionaryCard extends Vue {
  @Prop({ required: true }) readonly dictionary!: Dictionary | undefined

  private laterSnackbar = false
  private viewingLater = false

  get prettyDate (): string {
    return dayjs(this.dictionary?.modified).fromNow()
  }

  viewLater (): void {
    if (this.dictionary) {
      setViewLater(this.dictionary.id, !this.viewingLater).then(() => {
        this.viewingLater = !this.viewingLater
        this.laterSnackbar = true
      })
    }
  }

  @Watch('dictionary')
  onUpdateDictionary(): void {
    if (this.dictionary) {
      isViewLater(this.dictionary.id).then(vl => this.viewingLater = vl)
    }
  }
}
