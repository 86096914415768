























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Dictionary } from '@/api/dictionary'
import DictionaryCard from './DictionaryCard.vue'
import { RawLocation } from 'vue-router'

@Component({
  components: {
    DictionaryCard
  }
})
export default class DictionaryCardGroup extends Vue {
  @Prop({ required: true }) readonly dictionaries!: (Dictionary | undefined)[]
  @Prop({ type: String }) readonly label?: string
  @Prop() readonly moreLocation?: RawLocation
}
